export function formatPhoneNumber(phoneNumber: string | undefined) {
  if (typeof phoneNumber !== 'string') return phoneNumber
  if (phoneNumber.length === 10) return nationalFormat(phoneNumber)
  if (phoneNumber.length === 12) return internationalFormat(phoneNumber)
  return phoneNumber
}

function nationalFormat(phoneNumber: string) {
  if (!/^\d{10}$/.test(phoneNumber)) return phoneNumber
  return `+1-${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
    6,
    10,
  )}`
}
function internationalFormat(phoneNumber: string) {
  if (!/^\+\d{11}$/.test(phoneNumber)) return phoneNumber
  return `${phoneNumber.substring(0, 2)}-${phoneNumber.substring(2, 5)}-${phoneNumber.substring(
    5,
    8,
  )}-${phoneNumber.substring(8, 12)}`
}
